<template>
  <v-btn
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.add_comment')"
    data-quick-button-action-view="add_comment"
    type="button"
    small
    @click="showComment()"
  >
    <v-icon>
      mdi-comment
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeComment',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'dataViewer',
    'data'
  ],
  computed: {
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    showComment() {
      this.dataViewer['comment_visible'] = !this.dataViewer.comment_visible
    }
  }
}
</script>
